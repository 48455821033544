import { ArrowBottom, ArrowTop, Divider, TrashIcon } from 'assets';
import {
  ActionsWrapper,
  ArrowsBlock,
  OrderBottom,
  OrderTop,
  TrashBlock,
} from './styles';

const FieldActions = ({
  children,
  handleDeleteField,
  attributes,
  listeners,
}) => {
  return (
    <ActionsWrapper>
      <ArrowsBlock {...attributes} {...listeners}>
        <OrderTop>
          <ArrowTop />
        </OrderTop>
        <Divider />
        <OrderBottom>
          <ArrowBottom />
        </OrderBottom>
      </ArrowsBlock>
      {children}
      <TrashBlock onClick={handleDeleteField}>
        <TrashIcon />
      </TrashBlock>
    </ActionsWrapper>
  );
};

export default FieldActions;
