export const sampleTagsInfo = ({ data, languages, isEditMode, iconData }) => {
  if (data) {
    return isEditMode
      ? {
          id: data.id,
          name: data.templateName,
          tags: [
            languages?.map((lang) => {
              return {
                languageId: +lang?.id,
                value: data[`value${lang?.iso}`],
              };
            }),
          ],
          iconToken: !data.icon ? null : iconData?.[0]?.token || data.iconToken,
        }
      : {
          name: data.templateName,
          tags: [
            languages?.map((lang) => {
              return {
                languageId: +lang?.id,
                value: data[`value${lang?.iso}`],
              };
            }),
          ],
          iconToken: iconData?.[0]?.token,
        };
  }
};
