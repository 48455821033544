import {
  INTEGER_NUMBER,
  LINK_FORMAT,
  MAX_LENGTH_4,
  MAX_LENGTH_8,
  MIN_LENGTH_1,
  NUMBER_TO_HUNDREDTHS,
} from 'validations/messages';
import * as yup from 'yup';

export const addEditAnnouncementSchema = () => {
  return yup.object().shape({
    titleRU: yup.string().required(),
    titleEN: yup.string().required(),
    titleMD: yup.string().required(),
    aboutHousingRU: yup
      .array()
      .of(
        yup.object().shape({
          key: yup.string().required(),
          value: yup.string().required(),
        }),
      )
      .min(1, MIN_LENGTH_1)
      .max(8, MAX_LENGTH_8),
    aboutHousingMD: yup
      .array()
      .of(
        yup.object().shape({
          key: yup.string().required(),
          value: yup.string().required(),
        }),
      )
      .min(1, MIN_LENGTH_1)
      .max(8, MAX_LENGTH_8),
    aboutHousingEN: yup
      .array()
      .of(
        yup.object().shape({
          key: yup.string().required(),
          value: yup.string().required(),
        }),
      )
      .min(1, MIN_LENGTH_1)
      .max(8, MAX_LENGTH_8),
    aboutTheBuildingRU: yup.mixed().when('propertyType', {
      is: (propertyType) => propertyType?.value !== 'house',
      then: yup
        .array()
        .of(
          yup.object().shape({
            key: yup.string().required(),
            value: yup.string().required(),
          }),
        )
        .min(1, MIN_LENGTH_1)
        .max(4, MAX_LENGTH_4),
    }),
    aboutTheBuildingMD: yup.mixed().when('propertyType', {
      is: (propertyType) => propertyType?.value !== 'house',
      then: yup
        .array()
        .of(
          yup.object().shape({
            key: yup.string().required(),
            value: yup.string().required(),
          }),
        )
        .min(1, MIN_LENGTH_1)
        .max(4, MAX_LENGTH_4),
    }),
    aboutTheBuildingEN: yup.mixed().when('propertyType', {
      is: (propertyType) => propertyType?.value !== 'house',
      then: yup
        .array()
        .of(
          yup.object().shape({
            key: yup.string().required(),
            value: yup.string().required(),
          }),
        )
        .min(1, MIN_LENGTH_1)
        .max(4, MAX_LENGTH_4),
    }),
    aboutAreaRU: yup.mixed().when('propertyType', {
      is: (propertyType) => propertyType?.value === 'house',
      then: yup
        .array()
        .of(
          yup.object().shape({
            key: yup.string().required(),
            value: yup.string().required(),
          }),
        )
        .min(1, MIN_LENGTH_1)
        .max(4, MAX_LENGTH_4),
    }),
    aboutAreaMD: yup.mixed().when('propertyType', {
      is: (propertyType) => propertyType?.value === 'house',
      then: yup
        .array()
        .of(
          yup.object().shape({
            key: yup.string().required(),
            value: yup.string().required(),
          }),
        )
        .min(1, MIN_LENGTH_1)
        .max(4, MAX_LENGTH_4),
    }),
    aboutAreaEN: yup.mixed().when('propertyType', {
      is: (propertyType) => propertyType?.value === 'house',
      then: yup
        .array()
        .of(
          yup.object().shape({
            key: yup.string().required(),
            value: yup.string().required(),
          }),
        )
        .min(1, MIN_LENGTH_1)
        .max(4, MAX_LENGTH_4),
    }),
    price: yup
      .number()
      .positive('')
      .typeError()
      .integer(INTEGER_NUMBER)
      .required(),
    areafull: yup
      .number()
      .positive('')
      .typeError()
      .integer(INTEGER_NUMBER)
      .required(),
    landarea: yup.mixed().when('propertyType', {
      is: (propertyType) => propertyType?.value === 'house',
      then: yup
        .number()
        .positive('')
        .typeError()
        .test('', NUMBER_TO_HUNDREDTHS, (number) =>
          /^\d+(\.\d{1,2})?$/.test(number),
        ),
    }),
    rooms: yup.mixed().when('isStudio', {
      is: (isStudio) => {
        return !isStudio;
      },
      then: yup
        .number()
        .positive('')
        .typeError()
        .integer(INTEGER_NUMBER)
        .required(),
    }),
    floor: yup
      .object()
      .shape({
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .nullable(),
    floorcount: yup
      .object()
      .shape({
        value: yup.number().required(),
        label: yup.string().required(),
      })
      .nullable()
      .test((value, context) => {
        return context.parent.floor.value <= value.value;
      }),
    mapimg: yup.mixed().required(),
    seoimg: yup.mixed().required(),
    photos: yup.mixed().required(),
    link: yup.mixed().when('isAffiliateAnnouncement', {
      is: (isAffiliateAnnouncement) => {
        return !isAffiliateAnnouncement;
      },
      then: yup.string().url(LINK_FORMAT),
    }),
    video: yup.mixed().required(),
    districtRU: yup.string().when('district', {
      is: (district) => district?.value === 'other',
      then: yup.string().required(),
    }),
    districtMD: yup.string().when('district', {
      is: (district) => district?.value === 'other',
      then: yup.string().required(),
    }),
    districtEN: yup.string().when('district', {
      is: (district) => district?.value === 'other',
      then: yup.string().required(),
    }),
    heatingRU: yup.string().required(),
    heatingMD: yup.string().required(),
    heatingEN: yup.string().required(),
    cityRU: yup.string().when('city', {
      is: (city) => city?.value === 'other',
      then: yup.string().required(),
    }),
    cityMD: yup.string().when('city', {
      is: (city) => city?.value === 'other',
      then: yup.string().required(),
    }),
    cityEN: yup.string().when('city', {
      is: (city) => city?.value === 'other',
      then: yup.string().required(),
    }),
    agents: yup.object().shape({
      value: yup.number().required(),
      label: yup.string().required(),
    }),
    address: yup.string().when('city', {
      is: (city) => city?.value !== 'chisinau',
      then: yup.string().required(),
    }),
    addressChisinau: yup.mixed().when('city', {
      is: (city) => city?.value !== 'other',
      then: yup.mixed().test('', '', (field, schema) => {
        return schema?.from[0]?.value?.city?.value !== 'other' && field?.value;
      }),
    }),
    houseChisinau: yup.mixed().when('city', {
      is: (city) => city?.value !== 'other',
      then: yup.mixed().test('', '', (field, schema) => {
        return schema?.from[0]?.value?.city?.value !== 'other' && field?.value;
      }),
    }),
  });
};
