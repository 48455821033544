import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding-top: ${(props) => (props.isTop ? '0' : '16px')};
  padding-bottom: ${(props) => (props.isTop ? '16px' : '0')};

  @media (max-width: 768px) {
    width: calc(50% - 16px);
    margin-right: 16px;
  }
`;

export const CustomCheckbox = styled.input`
  margin: 0 16px 0 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: #161e41;
  opacity: 0.4;

  :checked {
    opacity: 1;
  }
`;

export const Label = styled.label`
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #9b9ea7;
  user-select: none;
`;
