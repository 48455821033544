export const ArrowTop = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 6L10.8287 8.82867L9.88534 9.77133L8 7.88533L6.11467 9.77133L5.17133 8.82867L8 6Z"
        fill="#161E41"
      />
    </svg>
  );
};
