import { yupResolver } from '@hookform/resolvers/yup';
import { CloseIcon, ConfirmIcon, PhotoDropIcon } from 'assets';
import { MessengerIcon } from 'assets/MessengerIcon';
import { TelegramIcon } from 'assets/TelegramIcon';
import { ViberIcon } from 'assets/ViberIcon';
import { WhatsAppIcon } from 'assets/WhatsAppIcon';
import Loader from 'components/Loader';
import CustomPhoneInput from 'components/PhoneInput';
import DropzoneFieldRHF from 'components/react-hook-from/DropzoneRHF';
import TextField from 'components/TextField';
import { agentInfo } from 'connectors/agentInfo';
import { SUCCESS_MESSAGES, ERRORS_MESSAGES, PATHS } from 'constants';
import {
  getAgentFieldsData,
  useAddAgent,
  useAddImage,
  useEditAgent,
  useGetAgentByIdQuery,
} from 'hooks';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { disableEnterSubmit } from 'utils';
import { addEditAgentSchema } from 'validations/schemas';
import {
  ActionButtons,
  AddEditAgentWrapper,
  AgencyBox,
  AgencyContainer,
  AgencyName,
  AgentInnerBlock,
  AgentName,
  AgentWrapperBlock,
  ButtonText,
  CancelButton,
  ConfirmButton,
  CreateTitle,
  LoaderWrapper,
  TitleInner,
  TitleText,
} from './styles';

const AddEditAgent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isEditMode = location?.state?.isEditMode;

  const {
    data: agent,
    isLoading: loadingAgentQuery,
    refetch,
  } = useGetAgentByIdQuery({
    id: location?.state?.agentId,
  });

  const { ADD_INITIAL_VALUES_AGENT, EDIT_INITIAL_VALUES_AGENT } =
    getAgentFieldsData({ agent });

  const methods = useForm({
    defaultValues: isEditMode
      ? EDIT_INITIAL_VALUES_AGENT
      : ADD_INITIAL_VALUES_AGENT,
    resolver: yupResolver(addEditAgentSchema()),
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const errorFieldsToast = () => {
    Object.keys(errors).length &&
      toast.error(ERRORS_MESSAGES.ERRORS_FIELDS_REQUIRED_NOLANG, {
        position: 'bottom-left',
      });
  };

  const { mutateAsync: addImage, isLoading: loadingImage } = useAddImage({
    onError(error) {
      toast.error(error?.response?.data?.message, {
        position: 'bottom-left',
      });
    },
  });

  const { mutateAsync: addAgent, isLoading: loadingAddAgent } = useAddAgent({
    onSuccess() {
      toast.success(SUCCESS_MESSAGES.ADD_AGENT_SUCCESS, {
        position: 'bottom-left',
      });
    },
    onError(error) {
      toast.error(error?.response?.data?.error, {
        position: 'bottom-left',
      });
    },
  });

  const { mutateAsync: editAgent, isLoading: loadingEditAgent } = useEditAgent({
    onSuccess() {
      refetch();
      toast.success(SUCCESS_MESSAGES.EDIT_AGENT_SUCCESS, {
        position: 'bottom-left',
      });
    },
    onError(error) {
      toast.error(error?.response?.data?.error, {
        position: 'bottom-left',
      });
    },
  });

  const handleResetForm = () => {
    reset(isEditMode ? EDIT_INITIAL_VALUES_AGENT : ADD_INITIAL_VALUES_AGENT);
  };

  const onSubmit = async (data) => {
    let agentPhotoData;

    if (!data?.agent.preview.includes(data?.agentToken)) {
      const formDataAgent = new FormData();
      formDataAgent.append('files', data?.agent);
      const agentPhotoResponse = await addImage(formDataAgent);
      agentPhotoData = agentPhotoResponse;
    }

    isEditMode
      ? editAgent(
          agentInfo({
            data,
            agentPhotoData,
            isEditMode,
          }),
        )
      : addAgent(
          agentInfo({
            data,
            agentPhotoData,
            isEditMode,
          }),
        );

    handleResetForm();
  };

  useEffect(() => {
    handleResetForm();
  }, [isEditMode, agent]);

  useEffect(() => {
    errorFieldsToast();
  }, [errors]);

  const handleCancelAgent = () => {
    return navigate(`${PATHS.agents}`);
  };

  if (loadingAgentQuery) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={disableEnterSubmit}
        noValidate
      >
        <AddEditAgentWrapper>
          <CreateTitle>
            <TitleInner addEdit>
              <TitleText>
                {isEditMode ? 'Редактирование агента' : 'Создание агента'}
              </TitleText>
              <Link to={PATHS.agents}>
                <CloseIcon />
              </Link>
            </TitleInner>
          </CreateTitle>
          <AgentWrapperBlock>
            <AgentInnerBlock>
              <AgencyContainer>
                <AgencyBox>
                  <DropzoneFieldRHF
                    name="agent"
                    imgBg={<PhotoDropIcon />}
                    circle
                  />
                  <AgentName>
                    <TextField
                      name="agentname"
                      label={'Имя агента'}
                      placeholder={'Введите имя'}
                      type="text"
                    />
                  </AgentName>
                </AgencyBox>
                <AgencyName>
                  <TextField
                    name="agencyname"
                    label={'Название агентства'}
                    placeholder={'Введите название'}
                    type="text"
                  />
                </AgencyName>
                <CustomPhoneInput name="phoneNumber" />
              </AgencyContainer>
              <TextField
                name="whatsapp"
                label={'WhatsApp'}
                placeholder={'Введите ссылку или номер'}
                type="text"
                leftIcon={<WhatsAppIcon />}
              />
              <TextField
                name="viber"
                label={'Viber'}
                placeholder={'Введите ссылку или номер'}
                type="text"
                leftIcon={<ViberIcon />}
              />
              <TextField
                name="messenger"
                label={'Messenger'}
                placeholder={'Введите ссылку или номер'}
                type="text"
                leftIcon={<MessengerIcon />}
              />
              <TextField
                name="telegram"
                label={'Telegram'}
                placeholder={'Введите ссылку или номер'}
                type="text"
                leftIcon={<TelegramIcon />}
              />
              <TextField
                name="aboutAgent"
                label={'Об агенте'}
                placeholder={'Введите информацию об агенте'}
                type="text"
              />
            </AgentInnerBlock>
          </AgentWrapperBlock>
          <ActionButtons>
            <CancelButton
              type="button"
              onClick={handleCancelAgent}
              disabled={loadingImage || loadingAddAgent || loadingEditAgent}
            >
              <CloseIcon fill={'#9B9EA7'} />
              <ButtonText>Отменить</ButtonText>
            </CancelButton>
            <ConfirmButton
              type="submit"
              disabled={loadingImage || loadingAddAgent || loadingEditAgent}
            >
              {loadingImage || loadingAddAgent || loadingEditAgent ? (
                <Loader width={'18px'} height={'18px'} fill={'#fff'} />
              ) : (
                <>
                  <ConfirmIcon />
                  <ButtonText active>
                    {isEditMode ? 'Сохранить' : 'Разместить'}
                  </ButtonText>
                </>
              )}
            </ConfirmButton>
          </ActionButtons>
        </AddEditAgentWrapper>
      </form>
    </FormProvider>
  );
};

export default AddEditAgent;
