import { AddIcon, CloseIcon } from 'assets';
import Loader from 'components/Loader';
import { templateTagPagination } from 'constants';
import {
  useGetLanguagesQuery,
  useGetTemplateTagsQuery,
  useTemplatesPagination,
} from 'hooks';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Modal from 'react-modal';
import {
  AddMoreButton,
  AddText,
  CheckboxWrapper,
  CloseWrapper,
  CustomCheckbox,
  Label,
  LoaderWrapper,
  modalCustomStyles,
  ModalTitle,
  ModalWrapper,
  TemplatesWrapper,
  WrapperLoader,
} from './styles';

const SamplesTagsModal = ({ modalIsOpen, closeModal, sectionName }) => {
  const { getValues, setValue } = useFormContext();
  const [activeIds, setActiveIds] = useState([]);

  const handleClose = () => {
    setActiveIds([]);
    closeModal();
  };

  const {
    data: templates,
    isLoading: loadingTemplatesTags,
    isFetching,
    isRefetching,
    fetchNextPage,
  } = useGetTemplateTagsQuery();

  const { templatesCurrentData, scrollHandler } = useTemplatesPagination({
    templates,
    fetchNextPage,
    isRefetching,
    modalIsOpen,
    limit: templateTagPagination.limit,
  });

  const { data: languages, isLoading: loadingLanguages } =
    useGetLanguagesQuery();

  const onCheckBoxChange = ({ event, id }) => {
    if (event.target.checked) {
      setActiveIds((prev) => [...prev, id]);
    } else {
      setActiveIds((prev) => {
        const newValue = [...prev];
        let index = newValue.findIndex((item) => item === id);
        newValue.splice(index, 1);
        return newValue;
      });
    }
  };

  const handleAddTemplatesTags = () => {
    languages?.map((lang) => {
      const formValue = getValues(`${sectionName.templateName}${lang?.iso}`);
      formValue.push(
        templatesCurrentData
          ?.filter((data) => activeIds.includes(data?.id))
          .map((item) => {
            const currentItem = item?.tags[0].find(
              (item) => item?.languageId === lang?.id,
            );
            return {
              languageId: currentItem.languageId,
              groupName: sectionName.inputName,
              name: currentItem.value,
            };
          }),
      );
      setValue(`${sectionName.templateName}${lang?.iso}`, formValue.flat());
    });

    handleClose();
  };

  if (loadingTemplatesTags || loadingLanguages) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleClose}
      style={modalCustomStyles}
      ariaHideApp={false}
      contentLabel="Samples tags modal"
    >
      <ModalWrapper>
        <CloseWrapper onClick={handleClose}>
          <CloseIcon />
        </CloseWrapper>
        <ModalTitle>Выберите шаблоны из списка</ModalTitle>
        <TemplatesWrapper onScroll={scrollHandler}>
          {templatesCurrentData.length
            ? templatesCurrentData?.map((template, index) => {
                return (
                  <CheckboxWrapper key={index}>
                    <Label>
                      <CustomCheckbox
                        checked={activeIds.includes(template?.id)}
                        type="checkbox"
                        onChange={(e) =>
                          onCheckBoxChange({
                            event: e,
                            id: template?.id,
                          })
                        }
                      />
                      {template?.iconToken && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/uploads/images/${template?.iconToken}`}
                          alt={template?.name}
                        />
                      )}
                      {template?.name}
                    </Label>
                  </CheckboxWrapper>
                );
              })
            : 'Шаблоны не найдены'}
        </TemplatesWrapper>
        {isFetching && (
          <WrapperLoader>
            <Loader width={'18px'} height={'18px'} />
          </WrapperLoader>
        )}
        <AddMoreButton type="button" onClick={handleAddTemplatesTags}>
          <AddIcon fill={'#fff'} />
          <AddText>Добавить</AddText>
        </AddMoreButton>
      </ModalWrapper>
    </Modal>
  );
};

export default SamplesTagsModal;
