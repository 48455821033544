import { PATHS } from 'constants';
import AddEditAnnouncement from 'pages/AddEditAnnouncement';
import Agents from 'pages/Agents';
import AddEditAgent from 'pages/Agents/AddEditAgent';
import Home from 'pages/Home';
import SamplesCustomFields from 'pages/SamplesCustomFields';
import AddEditSampleCustomField from 'pages/SamplesCustomFields/AddEditSampleCustomField';
import SamplesTags from 'pages/SamplesTags';
import AddEditSampleTags from 'pages/SamplesTags/AddEditSampleTags';
import Views from 'pages/Views';

export const routes = [
  {
    path: PATHS.home,
    component: Home,
  },
  {
    path: PATHS.addAnnouncement,
    component: AddEditAnnouncement,
  },
  {
    path: PATHS.editAnnouncement,
    component: AddEditAnnouncement,
  },
  {
    path: PATHS.agents,
    component: Agents,
  },
  {
    path: PATHS.addAgent,
    component: AddEditAgent,
  },
  {
    path: PATHS.editAgent,
    component: AddEditAgent,
  },
  {
    path: PATHS.samplesCustomFields,
    component: SamplesCustomFields,
  },
  {
    path: PATHS.addCustomField,
    component: AddEditSampleCustomField,
  },
  {
    path: PATHS.editCustomFieldId,
    component: AddEditSampleCustomField,
  },
  {
    path: PATHS.samplesTags,
    component: SamplesTags,
  },
  {
    path: PATHS.addTag,
    component: AddEditSampleTags,
  },
  {
    path: PATHS.editTagId,
    component: AddEditSampleTags,
  },
  {
    path: PATHS.views,
    component: Views,
  },
];
