import { getFieldLabelLang, handleAddOrder } from 'utils';

export const announcementInfo = ({
  data,
  mapImgData,
  imagesData,
  videoData,
  seoImgData,
  isEditMode,
}) => {
  if (data) {
    const isRent = data.announcementType?.value === 'RENT';
    const propertyType = data.propertyType?.value;
    const isNotApartments = data.propertyType?.value !== 'apartments';
    const isChisinau = data.city?.value === 'chisinau';

    const abouthouseRU = handleAddOrder(data.aboutHousingRU);
    const abouthouseMD = handleAddOrder(data.aboutHousingMD);
    const abouthouseEN = handleAddOrder(data.aboutHousingEN);
    const aboutbuildRU = handleAddOrder(data.aboutTheBuildingRU);
    const aboutbuildMD = handleAddOrder(data.aboutTheBuildingMD);
    const aboutbuildEN = handleAddOrder(data.aboutTheBuildingEN);
    const aboutareaRU = handleAddOrder(data.aboutAreaRU);
    const aboutareaMD = handleAddOrder(data.aboutAreaMD);
    const aboutareaEN = handleAddOrder(data.aboutAreaEN);

    const getCustomFields = () => {
      switch (propertyType) {
        case 'house':
          return [
            ...abouthouseRU,
            ...abouthouseMD,
            ...abouthouseEN,
            ...aboutareaRU,
            ...aboutareaMD,
            ...aboutareaEN,
          ];
        case 'apartments':
          return [
            ...abouthouseRU,
            ...abouthouseMD,
            ...abouthouseEN,
            ...aboutbuildRU,
            ...aboutbuildMD,
            ...aboutbuildEN,
          ];
        default:
          return [
            ...abouthouseRU,
            ...abouthouseMD,
            ...abouthouseEN,
            ...aboutbuildRU,
            ...aboutbuildMD,
            ...aboutbuildEN,
            ...aboutareaRU,
            ...aboutareaMD,
            ...aboutareaEN,
          ];
      }
    };

    const customFields = getCustomFields();

    const tagsRU = [
      ...(isRent && data.tagsRulesRU ? data.tagsRulesRU : []),
      ...(propertyType === 'house'
        ? data.tagsCommunicationsRU
        : data.tagsComfortRU),
      ...data.tagsInnerRU,
      ...data.tagsMapRU,
      ...data.optionGeneralRU,
    ];
    const tagsMD = [
      ...(isRent && data.tagsRulesMD ? data.tagsRulesMD : []),
      ...(propertyType === 'house'
        ? data.tagsCommunicationsMD
        : data.tagsComfortMD),
      ...data.tagsInnerMD,
      ...data.tagsMapMD,
      ...data.optionGeneralMD,
    ];
    const tagsEN = [
      ...(isRent && data.tagsRulesEN ? data.tagsRulesEN : []),
      ...(propertyType === 'house'
        ? data.tagsCommunicationsEN
        : data.tagsComfortEN),
      ...data.tagsInnerEN,
      ...data.tagsMapEN,
      ...data.optionGeneralEN,
    ];

    return isEditMode
      ? {
          announcementInfo: {
            RU: {
              id: data.idRU,
              announcementId: data.announcementId,
              title: data.titleRU,
              address: isChisinau ? data.addressChisinau?.label : data.address,
              typeOfProperty:
                propertyType !== 'house'
                  ? getFieldLabelLang(data.typeOfProperty?.label, 1)
                  : null,
              heating: data.heatingRU,
              tags: tagsRU,
              ...(data.city?.value !== 'other'
                ? { city: getFieldLabelLang(data.city?.label, 1) }
                : { city: data.cityRU }),
              ...(data.district?.value !== 'other'
                ? { district: getFieldLabelLang(data.district?.label, 1) }
                : { district: data.districtRU }),
              house: isChisinau ? data.houseChisinau?.label : null,
            },
            MD: {
              id: data.idMD,
              announcementId: data.announcementId,
              title: data.titleMD,
              address: isChisinau ? data.addressChisinau?.label : data.address,
              typeOfProperty:
                propertyType !== 'house'
                  ? getFieldLabelLang(data.typeOfProperty?.label, 2)
                  : null,
              heating: data.heatingMD,
              tags: tagsMD,
              ...(data.city?.value !== 'other'
                ? { city: getFieldLabelLang(data.city?.label, 2) }
                : { city: data.cityMD }),
              ...(data.district?.value !== 'other'
                ? { district: getFieldLabelLang(data.district?.label, 2) }
                : { district: data.districtMD }),
              house: isChisinau ? data.houseChisinau?.label : null,
            },
            EN: {
              id: data.idEN,
              announcementId: data.announcementId,
              title: data.titleEN,
              address: isChisinau ? data.addressChisinau?.label : data.address,
              typeOfProperty:
                propertyType !== 'house'
                  ? getFieldLabelLang(data.typeOfProperty?.label, 3)
                  : null,
              heating: data.heatingEN,
              district: data.districtEN,
              tags: tagsEN,
              ...(data.city?.value !== 'other'
                ? { city: getFieldLabelLang(data.city?.label, 3) }
                : { city: data.cityEN }),
              ...(data.district?.value !== 'other'
                ? { district: getFieldLabelLang(data.district?.label, 3) }
                : { district: data.districtEN }),
              house: isChisinau ? data.houseChisinau?.label : null,
            },
          },

          id: data.announcementId,
          mapImage: mapImgData?.[0]?.token || data.mapimgToken,
          seoImage: seoImgData?.[0]?.token || data.seoimgToken,
          price: +data.price,
          link3D: !data.isAffiliateAnnouncement ? data.link : null,
          video: videoData?.token || data.videoToken,
          roomsCount:
            !data.isStudio || propertyType === 'house' ? +data.rooms : null,
          areaTotal: +data.areafull,
          landArea: propertyType === 'house' ? +data.landarea : null,
          landAreaType:
            propertyType === 'house' ? data.landareaType?.value : null,
          floor: !isNotApartments ? data.floor.value : null,
          floorCount: data.floorcount?.value,
          images: imagesData || data.photos,
          announcementType: data.announcementType?.value,
          propertyType: data.propertyType?.value,
          agentId: +data.agents?.value,
          showAddress: isChisinau ? data.showAddress : false,
          isFavorite: data.isFavorite,
          isStudio: isNotApartments ? false : data.isStudio,
          isActive: !data.isNotActive,
          customFields: customFields,
          isAdvertisement: data.isAdvertisement,
          isAffiliateAnnouncement: data.isAffiliateAnnouncement,
          isOtherCity: data.isOtherCity,
          isRenovation: data.isRenovation,
        }
      : {
          announcementInfo: {
            RU: {
              title: data.titleRU,
              address: isChisinau ? data.addressChisinau?.label : data.address,
              typeOfProperty:
                propertyType !== 'house'
                  ? getFieldLabelLang(data.typeOfProperty?.label, 1)
                  : null,
              heating: data.heatingRU,
              district: data.districtRU,
              tags: tagsRU,
              ...(data.city?.value !== 'other'
                ? { city: getFieldLabelLang(data.city?.label, 1) }
                : { city: data.cityRU }),
              ...(data.district?.value !== 'other'
                ? { district: getFieldLabelLang(data.district?.label, 1) }
                : { district: data.districtRU }),
              house: isChisinau ? data.houseChisinau?.label : null,
            },
            MD: {
              title: data.titleMD,
              address: isChisinau ? data.addressChisinau?.label : data.address,
              typeOfProperty:
                propertyType !== 'house'
                  ? getFieldLabelLang(data.typeOfProperty?.label, 2)
                  : null,
              heating: data.heatingMD,
              district: data.districtMD,
              tags: tagsMD,
              ...(data.city?.value !== 'other'
                ? { city: getFieldLabelLang(data.city?.label, 2) }
                : { city: data.cityMD }),
              ...(data.district?.value !== 'other'
                ? { district: getFieldLabelLang(data.district?.label, 2) }
                : { district: data.districtMD }),
              house: isChisinau ? data.houseChisinau?.label : null,
            },
            EN: {
              title: data.titleEN,
              address: isChisinau ? data.addressChisinau?.label : data.address,
              typeOfProperty:
                propertyType !== 'house'
                  ? getFieldLabelLang(data.typeOfProperty?.label, 3)
                  : null,
              heating: data.heatingEN,
              district: data.districtEN,
              tags: tagsEN,
              ...(data.city?.value !== 'other'
                ? { city: getFieldLabelLang(data.city?.label, 3) }
                : { city: data.cityEN }),
              ...(data.district?.value !== 'other'
                ? { district: getFieldLabelLang(data.district?.label, 3) }
                : { district: data.districtEN }),
              house: isChisinau ? data.houseChisinau?.label : null,
            },
          },
          agentId: +data.agents?.value,
          mapImage: mapImgData[0]?.token,
          seoImage: seoImgData[0]?.token,
          price: data.price,
          link3D: !data.isAffiliateAnnouncement ? data.link : null,
          video: videoData?.token,
          roomsCount:
            !data.isStudio || propertyType === 'house' ? +data.rooms : null,
          areaTotal: +data.areafull,
          landArea: propertyType === 'house' ? +data.landarea : null,
          landAreaType:
            propertyType === 'house' ? data.landareaType?.value : null,
          floor: !isNotApartments ? data.floor.value : null,
          floorCount: data.floorcount.value,
          images: imagesData,
          announcementType: data.announcementType?.value,
          propertyType: data.propertyType?.value,
          showAddress: isChisinau ? data.showAddress : false,
          isFavorite: data.isFavorite,
          isStudio: isNotApartments ? false : data.isStudio,
          isActive: !data.isNotActive,
          customFields: customFields,
          isAdvertisement: data.isAdvertisement,
          isAffiliateAnnouncement: data.isAffiliateAnnouncement,
          isOtherCity: data.isOtherCity,
          isRenovation: data.isRenovation,
        };
  }
};
