import { AddIcon, CloseIcon } from 'assets';
import { useModalContext } from 'context/modal/modal.provider';

import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  AddMoreButton,
  AddText,
  AddWrapper,
  CustomTagFieldOption,
  CustomTagFieldWrapper,
  OptionRemove,
  OptionsList,
  OptionText,
  TitleText,
} from './styles';

const CustomTagField = ({
  name,
  inputName,
  setModalData,
  templateName,
  customTag,
  elemGroup,
  title,
  languages,
}) => {
  const { control, getValues, setValue } = useFormContext();
  const { fields } = useFieldArray({ control, name: name });
  const { handleOpenSamplesTags } = useModalContext();

  const handleDeleteOption = (index) => {
    languages.map((lang) => {
      let currentValue = getValues(`${templateName}${lang.iso}`);
      currentValue?.splice(index, 1);
      setValue(`${templateName}${lang.iso}`, currentValue);
    });
  };

  return (
    <CustomTagFieldWrapper>
      <TitleText customTag={customTag} elemGroup={elemGroup}>
        {title}
      </TitleText>
      <AddWrapper>
        <AddMoreButton
          onClick={() => {
            handleOpenSamplesTags();
            setModalData({ inputName, templateName });
          }}
          type="button"
        >
          <AddIcon fill={'#fff'} />
          <AddText>Выбрать из шаблона</AddText>
        </AddMoreButton>
      </AddWrapper>
      <OptionsList>
        {fields.map((field, index) => {
          return (
            <CustomTagFieldOption key={index}>
              <OptionText>{field.name}</OptionText>
              <OptionRemove onClick={() => handleDeleteOption(index)}>
                <CloseIcon width={16} height={16} fill={'#fff'} />
              </OptionRemove>
            </CustomTagFieldOption>
          );
        })}
      </OptionsList>
    </CustomTagFieldWrapper>
  );
};

export default CustomTagField;
