export const getAgentFieldsData = ({ agent }) => {
  // inital fields

  const ADD_INITIAL_VALUES_AGENT = {
    agentname: '',
    agencyname: '',
    phoneNumber: '',
    agent: null,
    aboutAgent: '',
    whatsapp: '',
    viber: '',
    messenger: '',
    telegram: '',
    documentsConfirmed: true,
  };

  // edit fields

  const EDIT_INITIAL_VALUES_AGENT = {
    id: agent?.id,
    agentname: agent?.personName,
    agencyname: agent?.agentName,
    phoneNumber: agent?.phone,
    agent: {
      preview:
        agent?.image &&
        `${process.env.REACT_APP_API_URL}/uploads/images/${agent?.image}`,
    },
    aboutAgent: agent?.about,
    whatsapp: agent?.contactInWhatsapp,
    viber: agent?.contactInViber,
    messenger: agent?.contactInMessenger,
    telegram: agent?.contactInTelegram,
    documentsConfirmed: true,
    agentToken: agent?.image,
  };

  return {
    ADD_INITIAL_VALUES_AGENT,
    EDIT_INITIAL_VALUES_AGENT,
  };
};
