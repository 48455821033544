export const Divider = () => {
  return (
    <svg
      width="8"
      height="2"
      viewBox="0 0 8 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.90735e-06" width="8" height="2" fill="#161E41" />
    </svg>
  );
};
