export const API_URL = process.env.REACT_APP_API_URL;

export const BREACKPOINTS = {
  languages: '/language',
  imgUpload: '/image/upload',
  announcementCreate: '/announcement/create',
  announcement: '/announcement',
  editAnnouncement: '/edit-announcement',
  addAgent: '/add-agent',
  editAgent: '/edit-agent',
  section: '/section',
  agent: '/agent',
  address: '/address',
  customField: '/template/field',
  templateTag: '/template/tag',
  statistic: '/statistic',
};

export const PATHS = {
  home: '/',
  agents: '/agents',
  addAgent: '/add-agent',
  editAgent: '/edit-agent/:agentId',
  addAnnouncement: '/add-announcement',
  editAnnouncement: '/edit-announcement/:announcementId',
  samplesCustomFields: '/samples-customfields',
  addCustomField: '/add-customfield',
  editCustomField: '/edit-customfield',
  editCustomFieldId: '/edit-customfield/:customFieldId',
  samplesTags: '/samples-tags',
  addTag: '/add-tag',
  editTag: '/edit-tag',
  editTagId: '/edit-tag/:tagId',
  views: '/views',
};

export const navigationLinks = [
  { name: 'Список объявлений', path: PATHS.home },
  { name: 'Просмотры', path: PATHS.views },
  { name: 'Список агентов', path: PATHS.agents },
  { name: 'Шаблоны жилья/здания', path: PATHS.samplesCustomFields },
  { name: 'Шаблоны тегов', path: PATHS.samplesTags },
];

export const floorCount = [
  { value: 0, label: '0' },
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 17, label: '17' },
  { value: 18, label: '18' },
  { value: 19, label: '19' },
  { value: 20, label: '20' },
];

export const DEFAULT_DROPZONE_OPTIONS = ({ isVideo, isSvg }) => {
  return {
    accept: isVideo
      ? { 'video/mp4': [] }
      : isSvg
      ? {
          'image/*': [],
        }
      : {
          'image/jpeg': [],
          'image/png': [],
        },
  };
};

export const SUCCESS_MESSAGES = {
  ADD_ANNOUNCEMENT_SUCCESS: 'Обьявление успешно создано',
  EDIT_ANNOUNCEMENT_SUCCESS: 'Обьявление успешно изменено',
  DELETE_ANNOUNCEMENT_SUCCESS: 'Обьявление успешно удалено',
  ADD_AGENT_SUCCESS: 'Агент успешно создан',
  EDIT_AGENT_SUCCESS: 'Агент успешно измененен',
  DELETE_AGENT_SUCCESS: 'Агент успешно удален',
  ADD_TEMPLATE_SUCCESS: 'Шаблон успешно создан',
  EDIT_TEMPLATE_SUCCESS: 'Шаблон успешно измененен',
  DELETE_TEMPLATE_SUCCESS: 'Шаблон успешно удален',
};

export const ERRORS_MESSAGES = {
  ERRORS_FIELDS_REQUIRED:
    'Заполните корректно все обязательные поля для всех языков',
  ERRORS_FIELDS_REQUIRED_NOLANG: 'Заполните корректно все обязательные поля',
};

export const tableHeadItems = [
  { title: 'ID' },
  { title: 'Тип предложения' },
  { title: 'Избранное' },
  { title: 'Заглавное фото' },
  { title: 'Заголовок' },
  { title: 'Цена, €' },
  { title: 'Общ. площадь, м2' },
  { title: 'Действия' },
];

export const tableHeadAgentItems = [
  { title: 'ID' },
  { title: 'Фото агента' },
  { title: 'Имя агента' },
  { title: 'Агенство' },
  { title: 'Номер телефона' },
  { title: 'Действия' },
];

export const tableHeadSamplesCustomFieldsItems = [
  { title: 'ID' },
  { title: 'Имя шаблона' },
  { title: 'Имя секции' },
  { title: 'Действия' },
];

export const tableHeadSamplesTagsItems = [
  { title: 'ID' },
  { title: 'Иконка' },
  { title: 'Имя шаблона' },
  { title: 'Действия' },
];

export const tableHeadViewsItems = [
  { title: 'ID' },
  { title: 'Уник. просмотров' },
  { title: 'Просмотров' },
  { title: 'Кликов на тел' },
  { title: 'Кликов на мессенджеры' },
  { title: 'Просмотров 3д тура' },
  { title: 'Просмотров видео до конца' },
];

export const fieldsArrayNames = {
  title: 'title',
  district: 'district',
  heating: 'heating',
  tagsName: 'tags',
  tagsComfortName: 'tagsComfort',
  tagsInnerName: 'tagsInner',
  tagsMapName: 'tagsMap',
  tagsRulesName: 'tagsRules',
  city: 'city',
  optionGeneralName: 'optionGeneral',
  tagsCommunicationsName: 'tagsCommunications',
};

export const tagsSortNames = {
  optionGeneral: 'general',
  tagsComfort: 'comfort',
  tagsInner: 'inner',
  tagsMap: 'map',
  tagsRules: 'rules',
  tagsCommunications: 'communications',
};

export const annoucementsPagination = {
  limit: 10,
};

export const agentsPagination = {
  limit: 10,
};

export const customFieldsPagination = {
  limit: 15,
};

export const templateTagPagination = {
  limit: 15,
};

export const statisticPagination = {
  limit: 25,
};

export const landAreaTypeOptions = [
  { value: 'HECTARE', label: 'Гектар' },
  { value: 'AR', label: 'Сотка' },
];

export const typeOfferOptions = [
  { value: 'SELL', label: 'Продажа' },
  { value: 'RENT', label: 'Аренда' },
];

export const typePropertyOptions = [
  { value: 'apartments', label: 'Квартира' },
  { value: 'house', label: 'Дом' },
];

export const typeOfPropertyOptions = [
  { value: 'new', label: 'Новостройка/Cladire nouă/New building' },
  { value: 'secondary', label: 'Вторичка/Revânzare/Resale' },
];

export const citiesOptions = [
  { value: 'chisinau', label: 'Кишинев/Chisinau/Chisinau' },
  { value: 'other', label: 'Свободная пропись' }, // required option
];

export const districtsOptions = [
  { value: 'buiucani', label: 'Буюканы/Buiucani/Buiucani' },
  { value: 'sculeanca', label: 'Скулянка/Sculeanca/Sculeanca' },
  { value: 'posta-veche', label: 'Старая почта/Poșta Veche/Poșta Veche' },
  { value: 'riscani', label: 'Рышкановка/Rîșcani/Rîșcani' },
  { value: 'ciocana', label: 'Чеканы/Ciocana/Ciocana' },
  { value: 'centru', label: 'Центр/Centru/Centru' },
  { value: 'botanica', label: 'Ботаника/Botanica/Botanica' },
  { value: 'telecentru', label: 'Телецентр/Telecentru/Telecentru' },
  { value: 'codru', label: 'Кодры/Codru/Codru' },
  { value: 'durlesti', label: 'Дурлешты/Durlești/Durlești' },
  { value: 'other', label: 'Свободная пропись' }, // required option
];
