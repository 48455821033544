import { AddIcon, CloseIcon } from 'assets';
import Loader from 'components/Loader';
import { useGetAllTemplates, useGetLanguagesQuery } from 'hooks';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Modal from 'react-modal';
import {
  AddMoreButton,
  AddText,
  CheckboxWrapper,
  CloseWrapper,
  CustomCheckbox,
  Label,
  LoaderWrapper,
  modalCustomStyles,
  ModalTitle,
  ModalWrapper,
  TemplatesWrapper,
  WrapperLoader,
} from './styles';

const SamplesCustomFieldsModal = ({ modalIsOpen, closeModal, sectionName }) => {
  const { getValues, setValue } = useFormContext();
  const [activeIds, setActiveIds] = useState([]);

  const handleClose = () => {
    closeModal();
    setActiveIds([]);
  };

  const {
    data: templates,
    isLoading: loadingTemplates,
    isFetching,
  } = useGetAllTemplates();

  const filterCurrentData = templates?.rows?.filter(
    (data) => data?.section?.alias === sectionName,
  );

  const { data: languages, isLoading: loadingLanguages } =
    useGetLanguagesQuery();

  const onCheckBoxChange = ({ event, id }) => {
    if (event.target.checked) {
      setActiveIds((prev) => [...prev, id]);
    } else {
      setActiveIds((prev) => {
        const newValue = [...prev];
        let index = newValue.findIndex((item) => item === id);
        newValue.splice(index, 1);
        return newValue;
      });
    }
  };

  const handleAddTemplates = () => {
    languages?.map((lang) => {
      const formValue = getValues(`${sectionName}${lang?.iso}`);

      const formValueResult =
        formValue?.length <= 1 && !formValue[0]?.key && !formValue[0]?.value
          ? []
          : formValue;
      formValueResult.push(
        templates?.rows
          ?.filter((data) => activeIds.includes(data?.id))
          .map((item) => {
            const currentItem = item?.fields[0].find(
              (item) => item?.languageId === lang?.id,
            );
            return { ...currentItem, sectionId: item.sectionId };
          }),
      );
      setValue(`${sectionName}${lang?.iso}`, formValueResult.flat());
    });

    handleClose();
  };

  if (loadingTemplates || loadingLanguages) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleClose}
      style={modalCustomStyles}
      ariaHideApp={false}
      contentLabel="Samples customfields modal"
    >
      <ModalWrapper>
        <CloseWrapper onClick={handleClose}>
          <CloseIcon />
        </CloseWrapper>
        <ModalTitle>Выберите шаблоны из списка</ModalTitle>
        <TemplatesWrapper>
          {filterCurrentData.length
            ? filterCurrentData?.map((template, index) => {
                return (
                  <CheckboxWrapper key={index}>
                    <Label>
                      <CustomCheckbox
                        checked={activeIds.includes(template?.id)}
                        type="checkbox"
                        onChange={(e) =>
                          onCheckBoxChange({
                            event: e,
                            id: template?.id,
                          })
                        }
                      />
                      {template?.name}
                    </Label>
                  </CheckboxWrapper>
                );
              })
            : 'Шаблоны не найдены'}
        </TemplatesWrapper>
        {isFetching && (
          <WrapperLoader>
            <Loader width={'18px'} height={'18px'} />
          </WrapperLoader>
        )}
        <AddMoreButton type="button" onClick={handleAddTemplates}>
          <AddIcon fill={'#fff'} />
          <AddText>Добавить</AddText>
        </AddMoreButton>
      </ModalWrapper>
    </Modal>
  );
};

export default SamplesCustomFieldsModal;
