export const TrashIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 4H14.6667V5.33333H13.3333V14C13.3333 14.1768 13.2631 14.3464 13.1381 14.4714C13.013 14.5964 12.8435 14.6667 12.6667 14.6667H3.33333C3.15652 14.6667 2.98695 14.5964 2.86193 14.4714C2.7369 14.3464 2.66667 14.1768 2.66667 14V5.33333H1.33333V4H4.66667V2C4.66667 1.82319 4.7369 1.65362 4.86193 1.5286C4.98695 1.40357 5.15652 1.33333 5.33333 1.33333H10.6667C10.8435 1.33333 11.013 1.40357 11.1381 1.5286C11.2631 1.65362 11.3333 1.82319 11.3333 2V4ZM12 5.33333H4V13.3333H12V5.33333ZM6 7.33333H7.33333V11.3333H6V7.33333ZM8.66667 7.33333H10V11.3333H8.66667V7.33333ZM6 2.66667V4H10V2.66667H6Z"
        fill="#0C0C0C"
      />
    </svg>
  );
};
