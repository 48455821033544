import { agentsOptions } from 'connectors/agentsOptions';
import {
  landAreaTypeOptions,
  tagsSortNames,
  districtsOptions,
  citiesOptions,
  typeOfPropertyOptions,
  typePropertyOptions,
  typeOfferOptions,
  fieldsArrayNames,
  floorCount,
} from 'constants';
import {
  getAddressLangLabel,
  getCustomFieldsArrays,
  getCustomFieldsArraysEdit,
  getInitEditFields,
  getInitFields,
} from 'utils';

export const getAnnouncementsFieldsData = ({
  languages,
  sections,
  announcement,
  agents,
}) => {
  const {
    title,
    district,
    heating,
    tagsName,
    tagsComfortName,
    tagsInnerName,
    tagsMapName,
    tagsRulesName,
    city,
    optionGeneralName,
    tagsCommunicationsName,
  } = fieldsArrayNames;

  // inital fields

  const customFieldsArrays = getCustomFieldsArrays(sections, languages);
  const titles = getInitFields({ languages, name: title });
  const districts = getInitFields({ languages, name: district });
  const heatings = getInitFields({ languages, name: heating });
  const cities = getInitFields({ languages, name: city });
  const tags = getInitFields({ languages, name: tagsName, isArr: true });
  const tagsComfort = getInitFields({
    languages,
    name: tagsComfortName,
    isArr: true,
  });

  const tagsInner = getInitFields({
    languages,
    name: tagsInnerName,
    isArr: true,
  });

  const tagsMap = getInitFields({
    languages,
    name: tagsMapName,
    isArr: true,
  });

  const tagsRules = getInitFields({
    languages,
    name: tagsRulesName,
    isArr: true,
  });

  const tagsCommunications = getInitFields({
    languages,
    name: tagsCommunicationsName,
    isArr: true,
  });

  const optionGeneral = getInitFields({
    languages,
    name: optionGeneralName,
    isArr: true,
  });

  const ADD_INITIAL_VALUES_ANNOUNCEMENT = {
    floor: floorCount[0],
    floorcount: floorCount[0],
    price: '',
    link: '',
    video: null,
    mapimg: null,
    photos: null,
    seoimg: null,
    areafull: '',
    landarea: '',
    rooms: '',
    comfort: '',
    inner: '',
    mapTags: '',
    general: '',
    announcementType: typeOfferOptions[0],
    propertyType: typePropertyOptions[0],
    typeOfProperty: typeOfPropertyOptions[0],
    city: citiesOptions[0],
    district: districtsOptions[0],
    landareaType: landAreaTypeOptions[0],
    agents: '',
    address: '',
    addressChisinau: '',
    houseChisinau: '',
    showAddress: false,
    isFavorite: false,
    isStudio: false,
    isNotActive: false,
    isAdvertisement: false,
    isAffiliateAnnouncement: false,
    isOtherCity: false,
    isRenovation: false,
  };

  const initValuesFull = {
    ...ADD_INITIAL_VALUES_ANNOUNCEMENT,
    ...customFieldsArrays,
    ...titles,
    ...districts,
    ...heatings,
    ...tags,
    ...tagsComfort,
    ...tagsInner,
    ...tagsMap,
    ...tagsRules,
    ...optionGeneral,
    ...cities,
    ...tagsCommunications,
  };

  // edit fields

  const titlesEdit = getInitEditFields({
    announcementInfo: announcement?.announcementInfo,
    name: title,
    languages: languages,
  });
  const districtsEdit = districtsOptions.find((item) =>
    item.label.includes(announcement?.announcementInfo?.[0].district),
  )
    ? ''
    : getInitEditFields({
        announcementInfo: announcement?.announcementInfo,
        name: district,
        languages: languages,
      });
  const heatingsEdit = getInitEditFields({
    announcementInfo: announcement?.announcementInfo,
    name: heating,
    languages: languages,
  });

  const citiesEdit = citiesOptions.find(
    (item) =>
      item.label.includes(announcement?.announcementInfo?.[0].city) &&
      !announcement?.isOtherCity,
  )
    ? ''
    : getInitEditFields({
        announcementInfo: announcement?.announcementInfo,
        name: city,
        languages: languages,
      });

  const tagsComfortEdit = getInitEditFields({
    announcementInfo: announcement?.announcementInfo,
    name: tagsComfortName,
    sortName: tagsSortNames.tagsComfort,
    languages: languages,
    isArray: true,
  });

  const tagsInnerEdit = getInitEditFields({
    announcementInfo: announcement?.announcementInfo,
    name: tagsInnerName,
    sortName: tagsSortNames.tagsInner,
    languages: languages,
    isArray: true,
  });

  const tagsMapEdit = getInitEditFields({
    announcementInfo: announcement?.announcementInfo,
    name: tagsMapName,
    sortName: tagsSortNames.tagsMap,
    languages: languages,
    isArray: true,
  });

  const tagsRulesEdit = getInitEditFields({
    announcementInfo: announcement?.announcementInfo,
    name: tagsRulesName,
    sortName: tagsSortNames.tagsRules,
    languages: languages,
    isArray: true,
  });

  const tagsCommunicationsEdit = getInitEditFields({
    announcementInfo: announcement?.announcementInfo,
    name: tagsCommunicationsName,
    sortName: tagsSortNames.tagsCommunications,
    languages: languages,
    isArray: true,
  });

  const optionGeneralEdit = getInitEditFields({
    announcementInfo: announcement?.announcementInfo,
    name: optionGeneralName,
    sortName: tagsSortNames.optionGeneral,
    languages: languages,
    isArray: true,
  });

  const customFieldsArraysEdit = getCustomFieldsArraysEdit({
    sections: sections,
    languages: languages,
    customFields: announcement?.customFields,
  });

  const announcementInfoId = getInitEditFields({
    announcementInfo: announcement?.announcementInfo,
    languages,
    name: 'id',
  });

  const EDIT_INITIAL_VALUES_ANNOUNCEMENT = {
    floor:
      floorCount.find((item) => item.value === announcement?.floor) ||
      floorCount[0],
    floorcount: floorCount.find(
      (item) => item.value === announcement?.floorCount,
    ),
    price: Math.floor(announcement?.price),
    link: !announcement?.isAffiliateAnnouncement ? announcement?.link3D : '',
    video: {
      preview: announcement?.video,
    },
    mapimg: {
      preview:
        announcement?.mapImage &&
        `${process.env.REACT_APP_API_URL}/uploads/images/${announcement?.mapImage}`,
    },
    seoimg: announcement?.seoImage
      ? {
          preview: `${process.env.REACT_APP_API_URL}/uploads/images/${announcement?.seoImage}`,
        }
      : null,
    photos: announcement?.images
      ?.sort((a, b) => a.order - b.order)
      .map((photo) => {
        return {
          ...photo,
          preview:
            photo?.token &&
            `${process.env.REACT_APP_API_URL}/uploads/images/${photo?.token}`,
        };
      }),

    areafull: Math.floor(announcement?.areaTotal),
    landarea: announcement?.landArea ? +announcement?.landArea : '',
    rooms: announcement?.roomsCount,
    comfort: '',
    inner: '',
    mapTags: '',
    agentId: announcement?.agent?.id,
    announcementId: announcement?.id,
    mapimgToken: announcement?.mapImage,
    seoimgToken: announcement?.seoImage,
    agentToken: announcement?.agent?.image,
    photosTokens: announcement?.images.sort((a, b) => a.order - b.order),
    address: announcement?.announcementInfo?.[0]?.address,
    agents: agentsOptions({ agents })?.find(
      (option) => option?.value === announcement?.agentId,
    ),
    videoToken: announcement?.video,
    city: !announcement?.isOtherCity
      ? citiesOptions.find((item) =>
          item.label.includes(announcement?.announcementInfo?.[0]?.city),
        )
      : citiesOptions.find((item) => item.value === 'other'),

    announcementType: typeOfferOptions.find(
      (item) => item.value === announcement?.announcementType,
    ),
    propertyType: typePropertyOptions.find(
      (item) => item.value === announcement?.propertyType,
    ),
    typeOfProperty:
      typeOfPropertyOptions.find((item) =>
        item.label.includes(
          announcement?.announcementInfo?.[0]?.typeOfProperty,
        ),
      ) || typeOfPropertyOptions[0],
    landareaType:
      landAreaTypeOptions.find(
        (item) => item.value === announcement?.landAreaType,
      ) || landAreaTypeOptions[0],
    district:
      districtsOptions.find((item) =>
        item.label.includes(announcement?.announcementInfo?.[0]?.district),
      ) || districtsOptions.find((item) => item.value === 'other'),
    addressChisinau: {
      value: `${getAddressLangLabel(announcement, 2)} ${getAddressLangLabel(
        announcement,
        1,
      )}`,
      label: announcement?.announcementInfo?.[0]?.address,
    },
    houseChisinau: {
      value: announcement?.announcementInfo?.[0]?.house,
      label: announcement?.announcementInfo?.[0]?.house,
    },
    showAddress: announcement?.showAddress,
    isFavorite: announcement?.isFavorite,
    isStudio: announcement?.isStudio,
    isNotActive: !announcement?.isActive,
    isAdvertisement: announcement?.isAdvertisement,
    isAffiliateAnnouncement: announcement?.isAffiliateAnnouncement,
    isOtherCity: announcement?.isOtherCity,
    isRenovation: announcement?.isRenovation,
  };

  const editValuesFull = {
    ...EDIT_INITIAL_VALUES_ANNOUNCEMENT,
    ...customFieldsArraysEdit,
    ...titlesEdit,
    ...districtsEdit,
    ...heatingsEdit,
    ...announcementInfoId,
    ...optionGeneralEdit,
    ...tagsComfortEdit,
    ...tagsInnerEdit,
    ...tagsMapEdit,
    ...tagsRulesEdit,
    ...citiesEdit,
    ...tagsCommunicationsEdit,
  };

  return {
    initValuesFull,
    editValuesFull,
  };
};
