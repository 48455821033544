import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import ModalContext from './modal.context';

const ModalProvider = ({ children }) => {
  const [modalSamplesCustomFieldsOpen, setModalSamplesCustomFieldsOpen] =
    useState(false);
  const [modalSamplesTagsOpen, setModalSamplesTagsOpen] = useState(false);

  const handleOpenSamplesCustomFields = () => {
    setModalSamplesCustomFieldsOpen(true);
  };

  const handleCloseSamplesCustomFields = () => {
    setModalSamplesCustomFieldsOpen(false);
  };

  const handleOpenSamplesTags = () => {
    setModalSamplesTagsOpen(true);
  };

  const handleCloseSamplesTags = () => {
    setModalSamplesTagsOpen(false);
  };

  const modalsArray = useMemo(() => {
    return [modalSamplesCustomFieldsOpen, modalSamplesTagsOpen];
  }, [modalSamplesCustomFieldsOpen, modalSamplesTagsOpen]);

  const disableModals = useCallback(() => {
    setModalSamplesCustomFieldsOpen(false);
    setModalSamplesTagsOpen(false);
  }, []);

  useEffect(() => {
    if (modalsArray.includes(true)) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [modalsArray]);

  useEffect(() => {
    window.addEventListener('popstate', disableModals);
    return () => {
      window.removeEventListener('popstate', disableModals);
    };
  }, [disableModals]);

  const value = {
    modalSamplesCustomFieldsOpen,
    modalSamplesTagsOpen,
    handleOpenSamplesCustomFields,
    handleCloseSamplesCustomFields,
    handleOpenSamplesTags,
    handleCloseSamplesTags,
  };

  return (
    <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
  );
};

export default ModalProvider;

export const useModalContext = () => {
  const modalContext = useContext(ModalContext);
  if (!modalContext) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }
  return modalContext;
};
