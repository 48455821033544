export const MapAddIcon = () => {
  return (
    <svg
      width="146"
      height="135"
      viewBox="0 0 146 135"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5062 87.1023C0.966138 86.7906 0.528442 86.0319 0.528442 85.4084V24.1104C0.528442 23.4865 0.966138 22.7282 1.5062 22.4164L23.4015 9.77507C23.9416 9.46333 24.79 9.44775 25.2968 9.74011C25.8036 10.0329 26.2143 10.7752 26.2143 11.3986V15.4963C26.2143 16.1202 26.652 16.3725 27.1925 16.0604L54.5917 0.241807C54.8432 0.09647 55.1617 0.0155869 55.4814 0H55.7093C55.997 0.0143231 56.2725 0.0829895 56.487 0.206421C56.9938 0.49878 57.4041 1.24189 57.4041 1.86537V96.0711L37.2229 107.723L1.5062 87.1023Z"
        fill="#161E41"
      />
      <path
        d="M56.487 0.206466C56.2725 0.0830353 55.997 0.0143688 55.7093 4.57764e-05H55.4814C55.1617 0.0156326 54.8432 0.0965158 54.5917 0.241853L27.1925 16.0604C26.652 16.3726 26.2143 16.1198 26.2143 15.4963V11.3987C26.2143 10.7752 25.8036 10.0325 25.2968 9.74015C24.7905 9.44737 23.9416 9.46338 23.4015 9.77512L1.5062 22.4165C1.23617 22.5724 0.991837 22.8403 0.814484 23.1465L13.3703 30.3961L57.4041 4.9731V1.86541C57.4041 1.24194 56.9933 0.498825 56.487 0.206466Z"
        fill="#778DC9"
      />
      <path
        d="M56.4267 1.30091L27.1917 18.1794C26.6516 18.4915 26.2139 18.2388 26.2139 17.6149V11.3987C26.2139 10.7752 25.7762 10.522 25.2362 10.8342L3.3404 23.4751C2.80034 23.7869 2.36264 24.5456 2.36264 25.169L2.36348 84.3494C2.36348 84.9729 2.80118 85.7312 3.34124 86.0433L39.0571 106.663L57.4037 96.0712V1.86541C57.4037 1.24193 56.9664 0.989175 56.4263 1.30091H56.4267Z"
        fill="#3D3E70"
      />
      <path
        d="M2.64995 85.3124C2.82688 85.6195 3.07121 85.8875 3.34167 86.0433L39.0579 106.663L57.4045 96.0712V53.7002L2.65037 85.3124H2.64995Z"
        fill="#161E41"
      />
      <path
        d="M7.08546 98.7959L6.81543 98.64C6.38321 98.3902 6.03314 97.7836 6.03314 97.2848L6.03271 39.7722C6.03271 39.2735 6.38279 38.6664 6.81501 38.417L65.7957 4.36437C66.2279 4.11456 66.9281 4.11456 67.3603 4.36437L67.6303 4.52024C68.0625 4.77005 68.4126 5.37625 68.4126 5.87503V63.3885C68.4126 63.8872 68.0625 64.4934 67.6303 64.7433L8.65004 98.7955C8.21782 99.0449 7.51726 99.0449 7.08504 98.7955L7.08546 98.7959Z"
        fill="#EEF2FA"
      />
      <path
        d="M9.70195 40.9876L6.26188 39.0013C6.12034 39.2461 6.03271 39.5229 6.03271 39.7722L6.03314 97.2848C6.03314 97.7836 6.38363 98.3907 6.81543 98.64L7.08546 98.7959C7.51768 99.0453 8.21782 99.0453 8.65047 98.7959L9.70195 98.1889V40.9876Z"
        fill="#9B9EA7"
      />
      <path
        d="M7.86775 98.3443V40.8313C7.86775 40.3325 8.21782 39.7263 8.65004 39.4765L67.6312 5.42385C68.0634 5.17446 68.4135 5.37667 68.4135 5.87545V63.3889C68.4135 63.8877 68.0634 64.4939 67.6312 64.7437L8.65089 98.7959C8.21867 99.0453 7.86859 98.8431 7.86817 98.3439L7.86775 98.3443Z"
        fill="#DBDFE8"
      />
      <path
        d="M12.5152 41.4822L63.766 11.8924C64.3061 11.5802 64.7438 11.8334 64.7438 12.4569V56.8066C64.7438 57.4305 64.3061 58.1888 63.766 58.5009L12.5152 88.0907C11.9751 88.4024 11.5374 88.1497 11.5374 87.5258L11.537 43.1761C11.537 42.5522 11.9747 41.7939 12.5147 41.4822H12.5152Z"
        fill="#FEFEFE"
      />
      <path
        d="M20.986 39.273C24.1779 37.43 26.7654 38.9238 26.7654 42.6095C26.7654 46.2947 24.1779 50.7766 20.986 52.6192C17.7945 54.4618 15.2066 52.968 15.2066 49.2823C15.2066 45.5967 17.7941 41.1152 20.9856 39.2726L20.986 39.273Z"
        fill="#DEAC61"
      />
      <path
        d="M26.3411 52.6394C27.1854 51.0694 29.3149 50.7766 30.5508 52.0614L37.2229 58.9955L49.8744 27.9524C50.6996 25.9273 53.4926 25.7361 54.5858 27.6301L64.7446 45.2251L64.7442 56.807C64.7442 57.4309 64.3065 58.1892 63.7665 58.5013L12.5156 88.0911C11.9755 88.4029 11.5378 88.1501 11.5378 87.5262L11.537 80.181L26.3411 52.6398V52.6394Z"
        fill="#6C7FB2"
      />
      <path
        d="M18.094 105.155L17.824 104.999C17.3918 104.75 17.0417 104.143 17.0417 103.644L17.0413 46.1317C17.0413 45.6329 17.3918 45.0259 17.8236 44.7765L76.8047 10.7242C77.2365 10.4744 77.937 10.4744 78.3693 10.7242L78.6393 10.8801C79.0715 11.1299 79.4216 11.7361 79.4216 12.2349V69.7483C79.4216 70.2471 79.0715 70.8537 78.6393 71.1031L19.6586 105.156C19.2264 105.405 18.5258 105.405 18.094 105.156V105.155Z"
        fill="#EEF2FA"
      />
      <path
        d="M20.7101 47.347L17.2704 45.3608C17.1289 45.606 17.0413 45.8823 17.0413 46.1321L17.0417 103.645C17.0417 104.144 17.3918 104.751 17.824 104.999L18.094 105.156C18.5258 105.405 19.2264 105.405 19.6586 105.156L20.7101 104.548V47.347Z"
        fill="#9B9EA7"
      />
      <path
        d="M18.8763 104.704L18.8759 47.1908C18.8759 46.692 19.2259 46.0854 19.6582 45.836L78.6393 11.7837C79.0715 11.5339 79.4216 11.7361 79.4216 12.2349V69.7483C79.4216 70.2471 79.0715 70.8537 78.6393 71.1031L19.6586 105.156C19.2264 105.405 18.8763 105.203 18.8763 104.704Z"
        fill="#DBDFE8"
      />
      <path
        d="M23.5237 47.8416L74.7742 18.2514C75.3142 17.9397 75.7523 18.1929 75.7523 18.8163V63.166C75.7523 63.7899 75.3142 64.5482 74.7742 64.8599L23.5237 94.4497C22.9836 94.7614 22.5459 94.5082 22.5459 93.8852V49.5351C22.5459 48.9112 22.9836 48.1529 23.5237 47.8412V47.8416Z"
        fill="#FEFEFE"
      />
      <path
        d="M31.9945 45.6325C35.1861 43.7899 37.7735 45.2833 37.7735 48.9689C37.7735 52.6542 35.1861 57.136 31.9945 58.9786C28.8022 60.8217 26.2152 59.327 26.2152 55.6422C26.2152 51.9565 28.8022 47.4751 31.9945 45.6325Z"
        fill="#DEAC61"
      />
      <path
        d="M37.3497 58.9989C38.1939 57.4288 40.3234 57.136 41.5598 58.4209L48.2318 65.3545L60.8833 34.3114C61.7086 32.2863 64.5012 32.0955 65.5943 33.9891L75.7532 51.5841L75.7528 63.166C75.7528 63.7899 75.3146 64.5482 74.7746 64.8599L23.5241 94.4497C22.9841 94.7614 22.5464 94.5083 22.5464 93.8852L22.5459 86.5396L37.3501 58.9984L37.3497 58.9989Z"
        fill="#6C7FB2"
      />
      <path
        d="M29.1017 111.508L28.8317 111.353C28.3994 111.103 28.0494 110.497 28.0494 109.998V52.4848C28.0494 51.986 28.3994 51.3798 28.8317 51.13L87.8124 17.0774C88.2446 16.8284 88.9451 16.8284 89.3774 17.0774L89.647 17.2336C90.0792 17.483 90.4297 18.0897 90.4297 18.5884V76.1014C90.4297 76.6006 90.0792 77.2073 89.647 77.4562L30.6667 111.509C30.2349 111.759 29.5343 111.758 29.1021 111.509L29.1017 111.508Z"
        fill="#EEF2FA"
      />
      <path
        d="M31.7182 53.7002L28.2781 51.7139C28.137 51.9591 28.049 52.2354 28.049 52.4844L28.0494 109.997C28.0494 110.496 28.3999 111.103 28.8317 111.352L29.1017 111.508C29.5339 111.758 30.2345 111.758 30.6663 111.508L31.7182 110.901V53.7002Z"
        fill="#9B9EA7"
      />
      <path
        d="M29.884 111.057V53.5443C29.884 53.0455 30.2345 52.4385 30.6663 52.1895L89.647 18.1368C90.0792 17.8874 90.4297 18.0897 90.4297 18.5889V76.1019C90.4297 76.6011 90.0792 77.2077 89.647 77.4567L30.6667 111.509C30.2349 111.759 29.884 111.557 29.884 111.058V111.057Z"
        fill="#DBDFE8"
      />
      <path
        d="M34.5314 54.1947L85.7823 24.605C86.3223 24.2932 86.7605 24.546 86.7605 25.1699V69.5196C86.7605 70.1435 86.3223 70.9017 85.7823 71.2135L34.5314 100.804C33.9918 101.115 33.5536 100.862 33.5536 100.239L33.5532 55.8891C33.5532 55.2652 33.9913 54.5069 34.5314 54.1952V54.1947Z"
        fill="#FEFEFE"
      />
      <path
        d="M43.0022 51.9856C46.1942 50.1426 48.7812 51.6368 48.7812 55.322C48.7816 59.0077 46.1942 63.4896 43.0022 65.3318C39.8103 67.1744 37.2229 65.681 37.2229 61.9957C37.2229 58.3101 39.8107 53.8282 43.0022 51.9856Z"
        fill="#DEAC61"
      />
      <path
        d="M48.3569 65.352C49.2012 63.7819 51.3307 63.4891 52.5671 64.7736L59.2395 71.708L71.8906 40.6649C72.7159 38.6399 75.5089 38.4486 76.6016 40.3426L86.7609 57.9377L86.7605 69.5196C86.7605 70.1435 86.3223 70.9017 85.7823 71.2135L34.5314 100.804C33.9918 101.115 33.5536 100.862 33.5536 100.239L33.5532 92.894L48.3574 65.3528L48.3569 65.352Z"
        fill="#6C7FB2"
      />
      <path
        d="M39.0575 106.663L44.5618 103.486V109.842L39.0575 106.663Z"
        fill="#161E41"
      />
      <path
        d="M44.5618 62.2439V108.712C44.5618 109.336 44.1236 109.589 43.5836 109.277L39.0575 106.663L37.2229 107.723L45.4186 112.455C45.9587 112.767 46.8341 112.767 47.3746 112.455L104.13 79.6873C104.669 79.3755 105.107 78.6172 105.107 77.9933V29.4066C105.107 28.7827 104.696 28.0404 104.19 27.7476C103.683 27.4548 102.835 27.4708 102.294 27.7826L45.5391 60.5504C44.9995 60.8621 44.5618 61.6204 44.5618 62.2439Z"
        fill="#161E41"
      />
      <path
        d="M48.231 63.2339L105.107 30.3961V29.4066C105.107 28.7827 104.697 28.0396 104.19 27.7476C103.684 27.4548 102.835 27.4708 102.295 27.7826L45.5395 60.5504C45.2699 60.7062 45.0252 60.9738 44.8482 61.28L48.231 63.2334V63.2339Z"
        fill="#778DC9"
      />
      <path
        d="M104.13 28.8421L47.3746 61.6094C46.8345 61.9212 46.3964 62.6795 46.3964 63.3034L46.3968 111.891C46.3968 112.514 46.8345 112.766 47.3746 112.455L104.13 79.6868C104.669 79.3751 105.107 78.6168 105.107 77.9929V29.4061C105.107 28.7822 104.669 28.5295 104.13 28.8412V28.8421Z"
        fill="#3D3E70"
      />
      <path
        d="M61.8564 65.9599H61.8602H61.8564ZM89.9962 50.02C89.9275 50.02 89.8433 50.0465 89.7531 50.0983L61.9626 66.1435C61.5957 66.355 61.2865 66.8913 61.2865 67.3147V90.9309C61.2865 91.0905 61.3315 91.2081 61.4095 91.2531C61.4874 91.2982 61.6117 91.2784 61.7503 91.1984L89.5412 75.1532C89.9082 74.9417 90.2174 74.4058 90.2174 73.982L90.217 50.3663C90.217 50.2062 90.1723 50.0886 90.0944 50.0436L89.9962 50.02ZM61.5068 91.7026C61.396 91.7026 61.2915 91.6752 61.1972 91.6213C60.981 91.497 60.8618 91.2514 60.8618 90.9313V67.3151C60.8618 66.7396 61.2519 66.0635 61.7503 65.7762L89.5412 49.731C89.8193 49.5705 90.0906 49.5515 90.3067 49.6762C90.5228 49.8009 90.6416 50.0461 90.6416 50.3667L90.642 73.9825C90.642 74.5583 90.2519 75.234 89.7531 75.5214L61.9626 91.5666C61.8059 91.6571 61.6509 91.7026 61.5068 91.7026Z"
        fill="#D8DDF1"
      />
      <path
        d="M67.9905 65.5634C67.7209 65.5634 67.4155 65.6574 67.0949 65.8427C65.9153 66.5239 64.9557 68.1858 64.9557 69.5474C64.9557 70.1612 65.152 70.6119 65.5071 70.8171C65.8627 71.0222 66.3514 70.9662 66.8826 70.6599C68.0621 69.9792 69.0218 68.3168 69.0218 66.9553C69.0218 66.3415 68.8259 65.8904 68.4703 65.6852C68.3292 65.6043 68.1674 65.5634 67.9905 65.5634ZM65.9857 71.3639C65.7355 71.3639 65.5025 71.3045 65.2952 71.1853C64.8028 70.9009 64.5315 70.3196 64.5315 69.5474C64.5315 68.0502 65.5863 66.2236 66.883 65.4746C67.5507 65.0891 68.1902 65.0335 68.6831 65.3174C69.1755 65.6018 69.4468 66.1831 69.4468 66.9553C69.4468 68.4525 68.392 70.2795 67.0953 71.0273C66.7082 71.2514 66.3307 71.3639 65.9857 71.3639Z"
        fill="#D8DDF1"
      />
      <path
        d="M61.0737 89.9278L60.9777 89.9047C60.8732 89.8516 60.8315 89.7239 60.885 89.6195L69.1161 73.4618C69.3706 72.9622 69.8335 72.6222 70.3867 72.5295C70.9385 72.4373 71.4874 72.6066 71.891 72.9946L80.0269 80.8238C80.1116 80.9051 80.1141 81.0395 80.0328 81.1242C79.9515 81.2089 79.8171 81.211 79.7325 81.1301L71.5965 73.3009C71.2903 73.0064 70.8741 72.8788 70.4566 72.9483C70.0379 73.0186 69.6874 73.276 69.4944 73.6543L61.2629 89.812C61.2254 89.8857 61.1512 89.9278 61.0733 89.9278H61.0737Z"
        fill="#D8DDF1"
      />
      <path
        d="M75.7519 77.217L75.6677 77.1997C75.5598 77.1525 75.5105 77.0278 75.5569 76.9204L81.9319 62.1971C82.1687 61.6507 82.662 61.2745 83.2522 61.1903C83.8423 61.1069 84.4212 61.3306 84.8003 61.7893L90.5931 68.7912C90.6677 68.8818 90.6547 69.0157 90.5645 69.0907C90.4748 69.164 90.3404 69.1526 90.2658 69.0621L84.473 62.0598C84.1886 61.7156 83.7556 61.5471 83.3116 61.6103C82.8692 61.6731 82.4993 61.9553 82.3216 62.3656L75.9465 77.0885C75.9116 77.1689 75.8336 77.217 75.7515 77.217H75.7519Z"
        fill="#D8DDF1"
      />
      <path
        d="M96.5941 39.6143C95.2861 39.6143 94.4065 38.8295 94.4065 37.6625L94.521 30.2992C94.521 28.2915 94.521 25.2419 96.7613 23.9486C97.8048 23.3458 98.9587 23.3201 99.9276 23.88C100.871 24.4242 101.456 25.4437 101.456 26.5411C101.456 27.0369 101.444 27.6254 101.42 28.2957L100.224 28.9967C100.161 28.8926 100.126 28.7692 100.131 28.6382C100.165 27.8319 100.182 27.1069 100.182 26.5415C100.182 25.8982 99.8408 25.3013 99.2906 24.9841C98.9595 24.7924 98.2737 24.5468 97.3983 25.0519C95.9175 25.9071 95.7949 28.0265 95.7949 30.3098L95.6804 37.6727C95.6804 38.2233 96.177 38.3412 96.5941 38.3412C97.6881 38.3412 99.203 37.422 99.203 35.6957L99.1579 32.7906C99.1524 32.4388 99.433 32.1494 99.7848 32.1435C100.131 32.1456 100.426 32.419 100.432 32.7708L100.477 35.686C100.477 38.1162 98.4611 39.6151 96.5941 39.6151V39.6143Z"
        fill="#DEAC61"
      />
      <path
        d="M106.16 84.8709L61.8564 110.449C61.4246 110.699 61.0741 111.305 61.0741 111.804V112.116C61.0741 112.615 61.4246 113.222 61.8564 113.471L98.8213 134.813C99.2531 135.062 99.9537 135.062 100.385 134.813L144.689 109.234C145.121 108.985 145.472 108.378 145.472 107.879V107.567C145.472 107.068 145.121 106.462 144.689 106.212L107.724 84.8709C107.292 84.6211 106.592 84.6211 106.16 84.8709Z"
        fill="#778DC9"
      />
      <path
        d="M61.0746 111.804V112.116C61.0746 112.615 61.4251 113.222 61.8569 113.471L98.8218 134.813C99.0379 134.938 99.321 135 99.604 135V131.027L62.9096 109.842L61.8569 110.449C61.4251 110.699 61.0746 111.305 61.0746 111.804Z"
        fill="#161E41"
      />
      <path
        d="M61.8564 110.449L106.16 84.8709C106.592 84.6211 107.292 84.6211 107.724 84.8709L144.689 106.212C145.121 106.462 145.121 106.866 144.689 107.116L100.386 132.694C99.9541 132.944 99.2531 132.944 98.8213 132.694L61.8564 111.353C61.4246 111.103 61.4246 110.699 61.8564 110.449Z"
        fill="#3D3E70"
      />
      <path
        d="M67.3611 111.353L96.0688 127.927C96.501 128.177 97.2011 128.177 97.6334 127.927L136.432 105.527C136.864 105.278 136.864 104.873 136.432 104.624L107.724 88.0481C107.292 87.7992 106.592 87.7992 106.159 88.0481L67.3607 110.449C66.9285 110.699 66.9285 111.103 67.3607 111.353H67.3611Z"
        fill="#FEFEFE"
      />
      <path
        d="M76.2107 107.851C78.2374 106.681 81.5228 106.681 83.5496 107.851C85.5759 109.022 85.5759 110.918 83.5496 112.089C81.5228 113.258 78.2374 113.258 76.2107 112.089C74.184 110.919 74.184 109.022 76.2107 107.851Z"
        fill="#DEAC61"
      />
      <path
        d="M136.433 104.624L128.042 99.7787L102.56 97.4066C101.209 97.2806 100.202 98.6278 100.706 99.8883L105.107 110.901L91.0839 110.1C89.9216 110.033 88.9982 111.062 89.1886 112.21L91.3464 125.201L96.0683 127.927C96.5006 128.177 97.2007 128.177 97.6329 127.927L136.432 105.527C136.864 105.278 136.864 104.873 136.432 104.624H136.433Z"
        fill="#6C7FB2"
      />
    </svg>
  );
};
