import axios from 'axios';
import {
  statisticPagination,
  templateTagPagination,
  customFieldsPagination,
  agentsPagination,
  annoucementsPagination,
  BREACKPOINTS,
  API_URL,
} from 'constants';

const instance = axios.create({
  baseURL: `${API_URL}/api`,
});

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => Promise.reject(error),
);

export const addAnnouncement = (data) => {
  return instance.post(BREACKPOINTS.announcementCreate, data);
};

export const getSection = (data) => {
  return instance.get(BREACKPOINTS.section, data);
};

export const getLanguages = (data) => {
  return instance.get(BREACKPOINTS.languages, data);
};

export const addImage = (data) => {
  return instance.post(BREACKPOINTS.imgUpload, data);
};

export const getAnnouncements = (data) => {
  return instance.get(BREACKPOINTS.announcement, {
    params: {
      limit: annoucementsPagination.limit,
      offset: data?.pageParam?.offset || '0',
    },
  });
};

export const getAnnouncementById = ({ id }) => {
  if (!id) return;
  return instance.get(BREACKPOINTS.announcement + `/${id}`);
};

export const deleteAnnouncement = ({ id }) => {
  if (!id) return;
  return instance.delete(BREACKPOINTS.announcement + `/${id}`);
};

export const editAnnouncement = (data) => {
  return instance.patch(BREACKPOINTS.announcement + `/${data.id}`, data);
};

export const getAgents = (data) => {
  return instance.get(BREACKPOINTS.agent, {
    params: {
      limit: agentsPagination.limit,
      offset: data?.pageParam?.offset || '0',
    },
  });
};

export const getAllAgents = () => {
  return instance.get(BREACKPOINTS.agent, {
    params: {
      limit: '1000',
      offset: '0',
    },
  });
};

export const addAgent = (data) => {
  return instance.post(BREACKPOINTS.agent, data);
};

export const getAgentById = ({ id }) => {
  if (!id) return;
  return instance.get(BREACKPOINTS.agent + `/${id}`);
};

export const deleteAgent = ({ id }) => {
  if (!id) return;
  return instance.delete(BREACKPOINTS.agent + `/${id}`);
};

export const editAgent = (data) => {
  return instance.patch(BREACKPOINTS.agent + `/${data.id}`, data);
};

export const getAddress = (data) => {
  return instance.get(BREACKPOINTS.address, data);
};

export const getTemplates = (data) => {
  return instance.get(BREACKPOINTS.customField, {
    params: {
      limit: customFieldsPagination.limit,
      offset: data?.pageParam?.offset || '0',
    },
  });
};

export const getAllTemplates = () => {
  return instance.get(BREACKPOINTS.customField, {
    params: {
      limit: '1000',
      offset: '0',
    },
  });
};

export const getTemplateById = ({ id }) => {
  if (!id) return;
  return instance.get(BREACKPOINTS.customField + `/${id}`);
};

export const addTemplates = (data) => {
  return instance.post(BREACKPOINTS.customField, data);
};

export const editTemplate = (data) => {
  return instance.patch(BREACKPOINTS.customField + `/${data.id}`, data);
};

export const deleteTemplate = ({ id }) => {
  if (!id) return;
  return instance.delete(BREACKPOINTS.customField + `/${id}`);
};

export const getTemplateTags = (data) => {
  return instance.get(BREACKPOINTS.templateTag, {
    params: {
      limit: templateTagPagination.limit,
      offset: data?.pageParam?.offset || '0',
    },
  });
};

export const getTemplateTagsById = ({ id }) => {
  if (!id) return;
  return instance.get(BREACKPOINTS.templateTag + `/${id}`);
};

export const addTemplateTags = (data) => {
  return instance.post(BREACKPOINTS.templateTag, data);
};

export const editTemplateTags = (data) => {
  return instance.patch(BREACKPOINTS.templateTag + `/${data.id}`, data);
};

export const deleteTemplateTags = ({ id }) => {
  if (!id) return;
  return instance.delete(BREACKPOINTS.templateTag + `/${id}`);
};

export const getStatisticViews = (data) => {
  return instance.get(BREACKPOINTS.statistic, {
    params: {
      limit: statisticPagination.limit,
      offset: data?.pageParam?.offset || '0',
    },
  });
};
