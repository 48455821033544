import { createContext } from 'react';

const defaultValue = {
  modalSamplesCustomFieldsOpen: false,
  modalSamplesTagsOpen: false,
  handleOpenSamplesCustomFields: () => {},
  handleCloseSamplesCustomFields: () => {},
  handleOpenSamplesTags: () => {},
  handleCloseSamplesTags: () => {},
};

export default createContext(defaultValue);
