import {
  getSampleTagsFields,
  getSampleTagsFieldsEdit,
} from 'utils/getSampleTagsFields';

export const getSampleTagsData = ({ languages, template }) => {
  // inital fields

  const sampleTagsFields = getSampleTagsFields({ languages });

  const ADD_INITIAL_VALUES_SAMPLES_TAGS = {
    templateName: '',
    icon: null,
    ...sampleTagsFields,
  };

  // edit fields

  const sampleTagsFieldsEdit = getSampleTagsFieldsEdit({ languages, template });

  const EDIT_INITIAL_VALUES_SAMPLES_TAGS = {
    id: template?.id,
    templateName: template?.name,
    icon: template?.iconToken
      ? {
          preview:
            template?.iconToken &&
            `${process.env.REACT_APP_API_URL}/uploads/images/${template?.iconToken}`,
        }
      : null,
    iconToken: template?.iconToken,
    ...sampleTagsFieldsEdit,
  };

  return {
    ADD_INITIAL_VALUES_SAMPLES_TAGS,
    EDIT_INITIAL_VALUES_SAMPLES_TAGS,
  };
};
