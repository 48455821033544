import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from '@dnd-kit/core';
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable';
import {
  AboutWrapper,
  AddMoreButton,
  AddText,
  ButtonsGroup,
  MaxLengthError,
} from './styles';
import { AddIcon } from 'assets';
import AboutСustomFieldsComponent from './AboutСustomFieldsComponent';
import { useModalContext } from 'context/modal/modal.provider';
import { getFieldName } from 'utils';

const AboutСustomFields = ({
  name,
  langId,
  sectionId,
  lang,
  setModalData,
  sectionName,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { handleOpenSamplesCustomFields } = useModalContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: name,
  });

  const hasError = getFieldName(errors, name);

  const handleAddNewField = () => {
    append({
      key: '',
      value: '',
      languageId: langId,
      sectionId: sectionId,
    });
  };

  const handleDeleteField = (index) => {
    remove(index);
  };

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const startIndex = fields.map((item) => item.id).indexOf(active.id);
      const endIndex = fields.map((item) => item.id).indexOf(over.id);
      move(startIndex, endIndex);
    }
  };

  const disableAddButton = () => {
    switch (name) {
      case `aboutHousing${lang}`:
        return fields.length >= 8;
      case `aboutTheBuilding${lang}`:
        return fields.length >= 4;
      case `aboutArea${lang}`:
        return fields.length >= 4;
      default:
        return false;
    }
  };

  return (
    <AboutWrapper>
      <DndContext
        autoScroll={false}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={fields} strategy={rectSortingStrategy}>
          {fields.map((field, index) => {
            return (
              <AboutСustomFieldsComponent
                field={field}
                key={field.id}
                index={index}
                name={name}
                handleDeleteField={handleDeleteField}
              />
            );
          })}
        </SortableContext>
      </DndContext>
      {hasError?.message && (
        <MaxLengthError>{hasError?.message}</MaxLengthError>
      )}
      <ButtonsGroup>
        <AddMoreButton
          onClick={() => {
            handleOpenSamplesCustomFields();
            setModalData(sectionName);
          }}
          type="button"
          empty={fields.length < 1}
          disabled={disableAddButton()}
        >
          <AddIcon fill={'#fff'} />
          <AddText>Выбрать из шаблона</AddText>
        </AddMoreButton>
        <AddMoreButton
          onClick={handleAddNewField}
          type="button"
          empty={fields.length < 1}
          disabled={disableAddButton()}
        >
          <AddIcon fill={'#fff'} />
          <AddText>Добавить еще</AddText>
        </AddMoreButton>
      </ButtonsGroup>
    </AboutWrapper>
  );
};

export default AboutСustomFields;
