import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import FieldActions from 'components/FieldActions';
import TextField from 'components/TextField';
import { AboutFieldsWrapper, InnerContainer } from './styles';

const AboutСustomFieldsComponent = ({
  field,
  index,
  name,
  handleDeleteField,
}) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useSortable({ id: field.id });

  const stylesDndBlock = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? '10' : 'auto',
    opacity: isDragging ? 0.6 : 1,
  };

  return (
    <AboutFieldsWrapper ref={setNodeRef} style={stylesDndBlock}>
      <FieldActions
        handleDeleteField={() => handleDeleteField(index)}
        listeners={listeners}
        attributes={attributes}
      >
        <InnerContainer>
          <TextField
            name={`${name}.${index}.key`}
            label={'Название *'}
            placeholder={'Введите название'}
            type="text"
          />
        </InnerContainer>
        <TextField
          name={`${name}.${index}.value`}
          label={'Значение *'}
          placeholder={'Введите значение'}
          type="text"
        />
      </FieldActions>
    </AboutFieldsWrapper>
  );
};

export default AboutСustomFieldsComponent;
