import {
  AreaIcon,
  CompassIcon,
  FloorIcon,
  HeatingIcon,
  HomeIcon,
  MapAddIcon,
  RoomsIcon,
} from 'assets';

import AboutСustomFields from 'components/AboutСustomFields';
import Checkbox from 'components/Checkbox';
import CustomTagField from 'components/CustomTagField';
import SamplesCustomFieldsModal from 'components/Modals/SamplesCustomFieldsModal';
import SamplesTagsModal from 'components/Modals/SamplesTagsModal';
import DropzoneFieldRHF from 'components/react-hook-from/DropzoneRHF';
import Select from 'components/Select';
import TextField from 'components/TextField';
import { addressOptionsList } from 'connectors/addressOptionsList';
import { agentsOptions } from 'connectors/agentsOptions';
import { housesOptionsList } from 'connectors/housesOptionsList';
import {
  landAreaTypeOptions,
  districtsOptions,
  citiesOptions,
  typeOfPropertyOptions,
  typePropertyOptions,
  typeOfferOptions,
  floorCount,
} from 'constants';
import { useModalContext } from 'context/modal/modal.provider';
import { getFilteredSections } from 'hooks';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  ContentInnerBlock,
  ContentWrapperBlock,
  FloorBox,
  FloorContainer,
  MainCheckboxBlock,
  TabWrapper,
  TitleText,
} from './styles';

const Tab = ({
  sections,
  lang,
  langId,
  agents,
  address,
  templates,
  languages,
}) => {
  const { watch, setValue } = useFormContext();
  const announcementType = watch('announcementType');
  const propertyType = watch('propertyType');
  const otherCityOption = watch('city');
  const otherDistrictOption = watch('district');
  const optionsAgents = agentsOptions({ agents });
  const addressChisinau = watch('addressChisinau');
  const studio = watch('isStudio');
  const addressOptions = addressOptionsList({ address });
  const housesOptions = housesOptionsList({ address, addressChisinau });
  const { filteredSection } = getFilteredSections({ propertyType, sections });
  const [modalData, setModalData] = useState('');
  const isHouse = propertyType?.value === 'house';
  const isAffiliateAnnouncement = watch('isAffiliateAnnouncement');

  const {
    modalSamplesCustomFieldsOpen,
    handleCloseSamplesCustomFields,
    modalSamplesTagsOpen,
    handleCloseSamplesTags,
  } = useModalContext();

  const handleCityChange = () => {
    setValue('addressChisinau', '');
    setValue('houseChisinau', '');
    setValue('address', '');
  };

  const handleAddressChisinauChange = () => {
    setValue('houseChisinau', '');
  };

  useEffect(() => {
    if (otherCityOption?.value === 'other') {
      setValue(
        'district',
        districtsOptions.find((item) => item.value === 'other'),
      );
      setValue('isOtherCity', true);
    } else {
      setValue('isOtherCity', false);
    }
  }, [otherCityOption]);

  return (
    <TabWrapper>
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <MainCheckboxBlock>
            <Checkbox name="isFavorite" label="Отметить как избранное" isTop />
            <Checkbox name="isNotActive" label="Не активно" isTop />
            <Checkbox name="isAdvertisement" label="Рекламируется" isTop />
            <Checkbox
              name="isAffiliateAnnouncement"
              label="От партнеров"
              isTop
            />
          </MainCheckboxBlock>
          <TitleText>Информация</TitleText>
          <Select
            name="announcementType"
            label={'Тип предложения *'}
            options={typeOfferOptions}
          />
          <Select
            name="propertyType"
            label={'Тип недвижимости *'}
            options={typePropertyOptions}
          />
          <Select
            name="city"
            label={'Город *'}
            options={citiesOptions}
            onInputChange={handleCityChange}
          />
          {otherCityOption?.value === 'other' && (
            <TextField
              name={`city${lang}`}
              label={'Город (свободная пропись) *'}
              placeholder={'Введите город'}
              type="text"
            />
          )}
          <TextField
            name={`title${lang}`}
            label={'Заголовок *'}
            placeholder={'Введите заголовок'}
            type="text"
          />
          <TextField
            name="price"
            label={'Цена *'}
            placeholder={'0'}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
          />
          {otherCityOption?.value !== 'other' ? (
            <Select
              name={'addressChisinau'}
              label={'Адрес *'}
              placeholder={'Введите улицу или выберите из списка'}
              options={addressOptions}
              isSearchable
              isClearable
              onInputChange={handleAddressChisinauChange}
            />
          ) : (
            <TextField
              name={`address`}
              label={'Адрес *'}
              placeholder={'Введите заголовок'}
              type="text"
            />
          )}
          {otherCityOption?.value !== 'other' && addressChisinau?.value && (
            <>
              <Select
                name={'houseChisinau'}
                label={'Номер дома *'}
                placeholder={'Введите номер дома или выберите из списка'}
                options={housesOptions}
                isSearchable
                isClearable
              />
              <Checkbox name="showAddress" label="Показывать номер дома" />
            </>
          )}
          <ContentWrapperBlock elemGroup>
            <ContentInnerBlock>
              <CustomTagField
                name={`optionGeneral${lang}`}
                templateName="optionGeneral"
                inputName="general"
                setModalData={setModalData}
                languages={languages}
                title="Опции основного блока"
                customTag
                elemGroup
              />
            </ContentInnerBlock>
          </ContentWrapperBlock>
          <SamplesTagsModal
            modalIsOpen={modalSamplesTagsOpen}
            closeModal={handleCloseSamplesTags}
            sectionName={modalData}
          />
        </ContentInnerBlock>
      </ContentWrapperBlock>
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <TitleText>Ссылки 3D Тура</TitleText>
          {!isAffiliateAnnouncement && (
            <TextField
              name="link"
              label={'Ссылка'}
              placeholder={'https://'}
              type="text"
            />
          )}
          <DropzoneFieldRHF
            name="video"
            dropText={'Добавить видео *'}
            dropBg={'#e2ffe7'}
            imgBg={<MapAddIcon />}
            isVideo
          />
          <DropzoneFieldRHF
            name="mapimg"
            dropText={'Добавить фото Карты *'}
            dropBg={'#e2ffe7'}
            imgBg={<MapAddIcon />}
            single
          />
          <DropzoneFieldRHF
            name="photos"
            dropText={'Добавить фото *'}
            imgBg={<MapAddIcon />}
            multiple
          />
          <DropzoneFieldRHF
            name="seoimg"
            dropText={'Добавить фото для SEO *'}
            dropBg={'#e2ffe7'}
            imgBg={<MapAddIcon />}
            single
          />
        </ContentInnerBlock>
      </ContentWrapperBlock>
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <TitleText>Статическая информация</TitleText>
          <TextField
            name="areafull"
            label={'Площадь общая м2 *'}
            placeholder={'0'}
            leftIcon={<AreaIcon />}
            type="number"
            inputMode="numeric"
            pattern="[0-9]*"
          />
          {(!studio || isHouse) && (
            <TextField
              name="rooms"
              label={'Кол-во комнат *'}
              placeholder={'0'}
              leftIcon={<RoomsIcon />}
              type="number"
              inputMode="numeric"
              pattern="[0-9]*"
            />
          )}
          {propertyType?.value === 'apartments' && (
            <Checkbox name="isStudio" label="Студия" />
          )}
          <Checkbox name="isRenovation" label="С ремонтом" />
          <FloorContainer>
            {!isHouse && (
              <FloorBox>
                <Select
                  name="floor"
                  label={'Этаж квартиры *'}
                  options={floorCount}
                  leftIcon={<FloorIcon />}
                />
              </FloorBox>
            )}
            <Select
              name="floorcount"
              label={'Кол-во этажей *'}
              options={floorCount}
              leftIcon={<FloorIcon />}
            />
          </FloorContainer>
          {!isHouse ? (
            <Select
              name={'typeOfProperty'}
              label={'Вид собственности *'}
              options={typeOfPropertyOptions}
              leftIcon={<HomeIcon />}
            />
          ) : (
            <>
              <TextField
                name="landarea"
                label={'Площадь участка *'}
                placeholder={'0'}
                leftIcon={<HomeIcon />}
                type="number"
                inputMode="numeric"
                pattern="[0-9]*"
              />
              <Select
                name={'landareaType'}
                label={'Единицы измерения *'}
                options={landAreaTypeOptions}
                leftIcon={<HomeIcon />}
              />
            </>
          )}
          <Select
            name="district"
            label={'Район *'}
            options={districtsOptions}
            leftIcon={<CompassIcon />}
            disabled={otherCityOption?.value === 'other'}
          />
          {(otherDistrictOption?.value === 'other' ||
            otherCityOption?.value === 'other') && (
            <TextField
              name={`district${lang}`}
              label={'Район (свободная пропись) *'}
              placeholder={'Введите район'}
              leftIcon={<CompassIcon />}
              type="text"
            />
          )}
          <TextField
            name={`heating${lang}`}
            label={'Отопление *'}
            placeholder={'Центральное'}
            leftIcon={<HeatingIcon />}
            type="text"
          />
        </ContentInnerBlock>
      </ContentWrapperBlock>
      {filteredSection?.map((section) => {
        return (
          <ContentWrapperBlock key={section?.id}>
            <ContentInnerBlock>
              <TitleText>{section?.name}</TitleText>
              <AboutСustomFields
                name={`${section?.alias}${lang}`}
                langId={langId}
                sectionId={section?.id}
                lang={lang}
                templates={templates}
                sectionName={section?.alias}
                setModalData={setModalData}
              />
            </ContentInnerBlock>
          </ContentWrapperBlock>
        );
      })}
      <SamplesCustomFieldsModal
        modalIsOpen={modalSamplesCustomFieldsOpen}
        closeModal={handleCloseSamplesCustomFields}
        sectionName={modalData}
      />
      {isHouse && (
        <ContentWrapperBlock>
          <ContentInnerBlock>
            <CustomTagField
              name={`tagsCommunications${lang}`}
              templateName="tagsCommunications"
              inputName="communications"
              title="Коммуникации"
              setModalData={setModalData}
              languages={languages}
              customTag
            />
          </ContentInnerBlock>
        </ContentWrapperBlock>
      )}
      {!isHouse && (
        <ContentWrapperBlock>
          <ContentInnerBlock>
            <CustomTagField
              name={`tagsComfort${lang}`}
              templateName="tagsComfort"
              inputName="comfort"
              title="Удобства в доме"
              setModalData={setModalData}
              languages={languages}
              customTag
            />
          </ContentInnerBlock>
        </ContentWrapperBlock>
      )}
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <CustomTagField
            name={`tagsInner${lang}`}
            templateName="tagsInner"
            inputName="inner"
            title="Что есть внутри"
            setModalData={setModalData}
            languages={languages}
            customTag
          />
        </ContentInnerBlock>
      </ContentWrapperBlock>
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <CustomTagField
            name={`tagsMap${lang}`}
            templateName="tagsMap"
            inputName="map"
            title="Места вокруг"
            setModalData={setModalData}
            languages={languages}
            customTag
          />
        </ContentInnerBlock>
      </ContentWrapperBlock>
      {announcementType?.value === 'RENT' && (
        <ContentWrapperBlock>
          <ContentInnerBlock>
            <CustomTagField
              name={`tagsRules${lang}`}
              templateName="tagsRules"
              inputName="rules"
              title="Правила"
              setModalData={setModalData}
              languages={languages}
              customTag
            />
          </ContentInnerBlock>
        </ContentWrapperBlock>
      )}
      <ContentWrapperBlock>
        <ContentInnerBlock>
          <TitleText>Агент</TitleText>
          <Select
            name="agents"
            label={'Выбор агента *'}
            options={optionsAgents}
            placeholder={'Выберите агента'}
            isSearchable
            isClearable
            menuOnTop
          />
        </ContentInnerBlock>
      </ContentWrapperBlock>
    </TabWrapper>
  );
};

export default Tab;
