export const ArrowBottom = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10L5.17134 7.17133L6.11467 6.22867L8 8.11467L9.88534 6.22867L10.8287 7.17133L8 10Z"
        fill="#161E41"
      />
    </svg>
  );
};
